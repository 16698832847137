import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiUrl} from '../helpers/ApiUrl';
import {filter} from 'rxjs/operators';
import {PlanFeatures} from '../models/planFeatures.model';
import {SharedAuthService} from './shared-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  setPlanFeatures = new BehaviorSubject<PlanFeatures>(null);
  getPlanFeatures = this.setPlanFeatures.pipe(filter(planFeature => planFeature !== null));

  constructor(private http: HttpClient,
              private sharedAuthService: SharedAuthService) {
  }

  loadFeatures(): Observable<any> {
    return this.http.get(ApiUrl.PLAN_FEATURES);
  }


  initializeApp(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.sharedAuthService.isUserLoggedIn().then(userId => {
        if (userId) {
          this.sharedAuthService.initialize(false);
          this.loadFeatures().subscribe({
            next: (features) => {
              this.setPlanFeatures.next(features);
              resolve();
            },
            error: (err) => {
              console.error('Failed to load app features:', err);
              resolve(); // Resolve even in case of an error to prevent blocking the app
            },
          });
        } else {
          resolve();
        }
      });
    });
  }
}
